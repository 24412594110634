.sweepstake-list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.link {
  text-decoration: none;
  color: inherit;
}
