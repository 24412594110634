$color: #12692763;

$primary-color: #2e5f99;
$primary-color-light: #229ac8;
$secondary-color: #9cca3b;

.color-primary {
  background-color: $primary-color;
}
.color-light {
  background-color: $primary-color-light;
}
.color-secondary {
  background-color: $secondary-color;
}

.form {
  background: white;
  border-radius: 20px;
  padding: 20px;
  margin: 20px !important;
}
