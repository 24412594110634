.admin-customer td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-width: 1px;
}

.admin-customer {
  padding: 10px;
}
