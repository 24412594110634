@import "./theme.scss";

body {
  background-color: $color;
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  overflow: visible !important;
}

.admin-content {
  margin-top: 64px;
  margin-left: 203px;
}

@media only screen and (max-width: 500px) {
  .admin-content {
    margin-top: 64px;
    margin-left: 0px;
    max-width: 100vw;
  }
}
