.admin-sweepstake {
  padding: 5px;
}
.inputWrapper {
  display: inline-flex;
  top: 12px;
  height: 34px;
  width: 37px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-image: url("../../../src/assets/images/edit-image.png");
  background-size: 34px;
  background-repeat: no-repeat;
}
.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  font-size: 50px;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
