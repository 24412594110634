.button-transparent {
  color: transparent;
  background-color: transparent;
  border: none;
  height: 45px;
  width: 107px;
}
.ticket-modal {
  position: fixed;
  z-index: 1111;
  top: 4px;
}
.modal-button {
  margin: 10px;
}
.ticket-modal button:focus {
  background-color: transparent;
}
.modal-dialog p {
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .button-transparent {
    width: 16vw;
  }
}
