p {
  /* color: #ffffff; */
}

/*-=-=-=-=-=-=-=-=-=-=- */
/* Flip Panel */
/*-=-=-=-=-=-=-=-=-=-=- */

.wrapper {
  width: 980px;
  margin: 0 auto;
  background-color: #bdd3de;
  overflow: hidden;
}

.ticket {
  position: relative;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
}

.ticket .front,
.ticket .back {
  text-align: center;
}

.ticket .front {
  height: inherit;
  top: 0;
  z-index: 900;
  text-align: center;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  -moz-transform: rotateX(0deg) rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ticket .back {
  height: inherit;
  position: absolute;
  top: 0;
  z-index: 1000;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.ticket.flip .front {
  z-index: 900;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}
.ticket.flip .back {
  z-index: 1000;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  -moz-transform: rotateX(0deg) rotateY(0deg);
}
.box {
  overflow: hidden;

  width: 106px;
  height: 48px;
  margin: 2px;
  border-width: 2px;
  border: 4px outset gray;
  border-color: white;
  border-radius: 16px;
  -moz-border-radius: 16px;
  -webkit-border-radius: 9px;
}

.box p {
  margin: 0px;
  line-height: 48px;
  font-size: 38px;
  font-family: "Bebas Neue", cursive;
}
.box .ticket-user {
  margin-top: -50px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;  letter-spacing: 1px;
  position: relative;
}
.box1 {
  background: white;
  color: black;
}
.box2 {
  background-color: lime;
}
.box-winner {
  background: lime;
  color: white;
}
.box-unavailable {
  background: red;
  color: white;
}
.text-fade {
  /* opacity: 0.4; */
  /* text-shadow: 0px 0px 4px #000000; */
}

.ticket-modal :focus {
  outline: none !important;
}

.ticket-winner {
  border-radius: 6px !important;
  border-width: 5px;
  border-color: yellow;
  border-style: dashed;
  margin-top: -8px;
  background: lime;
}
@media only screen and (max-width: 767px) {
  .box {
    width: 16vw;
  }
  .box p {
    font-size: 44px;
  }
}
