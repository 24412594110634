.tickets-div {
  position: relative;
  top: 20px;
}
.tickets-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  align-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 1279px) {
  /* .MuiGrid-justify-xs-flex-end {
    justify-content: center !important;
  }
  .MuiGrid-justify-md-flex-end {
    justify-content: center !important;
  }
  .MuiGrid-justify-xs-flex-start {
    justify-content: center !important;
  } */
  .tickets-view .MuiGrid-grid-md-12 {
    justify-content: center !important;
  }
}
