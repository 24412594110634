.main-menu {
  color: white;
  display: inline;
}
.link {
  text-decoration: none;
  color: inherit;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
